var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-0 px-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0 pb-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('v-card',{attrs:{"height":"240","elevation":"0"}},[_c('p',{staticClass:"text-center text-safe text-safe-blue pb-0 mb-0 pt-8 pb-8 pt-sm-10 pb-sm-10 pt-md-10 pb-md-10 pt-lg-14 pb-lg-14 pt-xl-15 pb-xl-15"},[_c('v-btn',{staticClass:"mt-0 mb-2",attrs:{"x-small":"","fab":"","icon":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('img',{attrs:{"width":"15","src":require("../../assets/icon/navigate_before.png")}})]),_vm._v(" Salvoconducto de ingreso al centro de operaciones ")],1)])],1)],1),_c('v-row',{staticClass:"mx-0 px-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pl-0 pr-0 pt-0",attrs:{"cols":"12","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('v-card',{attrs:{"color":_vm.color}},[_c('v-card-text',[_c('img',{staticClass:"img-profile-g",attrs:{"width":"100","src":_vm.img}}),_c('p',{staticClass:"text-safe-name mb-0 pb-0 mt-9 pt-9 pb-2",class:[
              _vm.result == 'success' ? 'text-safe-blue-l' : 'text-safe-while' ]},[_vm._v(" "+_vm._s(_vm.info.full_name)+" ")]),_c('p',{staticClass:"text-safe-dependence px-2 mb-0 pb-5",class:[
              _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" "+_vm._s(_vm.info.profile.name)+" ")]),_c('v-row',{staticClass:"ml-0 mr-0 mb-10",attrs:{"justify":"center"}},[(_vm.result == 'success')?_c('v-col',{staticClass:"pr-10 text-center",attrs:{"cols":"2"}},[_c('img',{staticClass:"mt-2",attrs:{"width":"40","src":_vm.origin + '/icon/calendar.svg'}})]):_c('v-col',{staticClass:"pr-10 text-center",attrs:{"cols":"2"}},[_c('img',{staticClass:"mt-2",attrs:{"width":"40","src":_vm.origin + '/icon/calendar-while.svg'}})]),_c('v-col',{staticClass:"text-left",attrs:{"cols":"7"}},[_c('p',{staticClass:"text-safe-date pb-0 mb-0",class:[
                  _vm.result == 'success' ? 'text-safe-gray' : 'text-safe-while' ]},[_vm._v(" Fecha ingreso ")]),_c('p',{staticClass:"text-safe-date-1 pb-3 mb-0",class:[
                  _vm.result == 'success' ? 'text-safe-gray' : 'text-safe-while' ]},[_vm._v(" "+_vm._s(_vm.date)+" ")])]),_c('v-col',{staticClass:"text-center pt-2",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-safe-title",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v("Documento ")]),_c('label',{staticClass:"text-safe-data",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" "+_vm._s(_vm.info.document_number)+" ")]),_c('br'),_c('label',{staticClass:"text-safe-title",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" Ciudad ")]),_c('label',{staticClass:"text-safe-data",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" Bogotá ")]),_c('br'),_c('label',{staticClass:"text-safe-title",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" Vacunación Covid ")]),_c('label',{staticClass:"text-safe-data",class:[
                  _vm.result == 'success' ? 'text-safe-blue' : 'text-safe-while' ]},[_vm._v(" Si ")])]),_c('v-col',{staticClass:"text-center pt-1",attrs:{"cols":"12"}},[(_vm.result == 'success')?[_c('img',{attrs:{"width":"100","src":_vm.origin + '/icon/success.svg'}}),_c('p',{staticClass:"text-safe-apto text-safe-blue"},[_vm._v("Apto para ingreso")])]:[_c('img',{attrs:{"width":"100","src":_vm.origin + '/icon/error.svg'}}),_c('p',{staticClass:"text-safe-apto text-safe-while"},[_vm._v(" No apto para ingreso ")])]],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }