<template>
  <div>
    <v-row class="mx-0 px-0" justify="center">
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pl-0 pr-0 pb-0">
        <v-card height="240" elevation="0">
          <p class="text-center text-safe text-safe-blue pb-0 mb-0 pt-8 pb-8 pt-sm-10 pb-sm-10 pt-md-10 pb-md-10 pt-lg-14 pb-lg-14 pt-xl-15 pb-xl-15">
            <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            Salvoconducto de ingreso al centro de operaciones
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 px-0" justify="center">
      <v-col cols="12" sm="6" md="4" lg="4" xl="4" class="pl-0 pr-0 pt-0">
        <v-card :color="color">
          <v-card-text>
            <img class="img-profile-g" width="100" :src="img" />
            <p
              :class="[
                result == 'success' ? 'text-safe-blue-l' : 'text-safe-while',
              ]"
              class="text-safe-name mb-0 pb-0 mt-9 pt-9 pb-2"
            >
              {{ info.full_name }}
            </p>
            <p
              :class="[
                result == 'success' ? 'text-safe-blue' : 'text-safe-while',
              ]"
              class="text-safe-dependence px-2 mb-0 pb-5"
            >
              {{ info.profile.name }}
            </p>
            <v-row justify="center" class="ml-0 mr-0 mb-10">
              <v-col v-if="result == 'success'" cols="2" class="pr-10 text-center">
                <img
                  class="mt-2"
                  width="40"
                  :src="origin + '/icon/calendar.svg'"
                />
              </v-col>
              <v-col v-else cols="2" class="pr-10 text-center">
                <img
                  class="mt-2"
                  width="40"
                  :src="origin + '/icon/calendar-while.svg'"
                />
              </v-col>
              <v-col cols="7" class="text-left">
                <p
                  :class="[
                    result == 'success' ? 'text-safe-gray' : 'text-safe-while',
                  ]"
                  class="text-safe-date pb-0 mb-0"
                >
                  Fecha ingreso
                </p>
                <p
                  :class="[
                    result == 'success' ? 'text-safe-gray' : 'text-safe-while',
                  ]"
                  class="text-safe-date-1 pb-3 mb-0"
                >
                  {{ date }}
                </p>
              </v-col>
              <v-col cols="12" class="text-center pt-2">
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-title"
                  >Documento
                </label>
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-data"
                >
                  {{ info.document_number }}
                </label>
                <br />
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-title"
                >
                  Ciudad
                </label>
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-data"
                >
                  Bogotá
                </label>
                <br />
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-title"
                >
                  Vacunación Covid
                </label>
                <label
                  :class="[
                    result == 'success' ? 'text-safe-blue' : 'text-safe-while',
                  ]"
                  class="text-safe-data"
                >
                  Si
                </label>
              </v-col>
              <v-col cols="12" class="text-center pt-1">
                <template v-if="result == 'success'">
                  <img width="100" :src="origin + '/icon/success.svg'" />
                  <p class="text-safe-apto text-safe-blue">Apto para ingreso</p>
                </template>
                <template v-else>
                  <img width="100" :src="origin + '/icon/error.svg'" />
                  <p class="text-safe-apto text-safe-while">
                    No apto para ingreso
                  </p>
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      origin: window.location.origin,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      infoToken: {
        document: "",
        fullName: "",
      },
      info: {
        profile: {
          name: "",
          id: "",
        },
      },
      img: "",
      name: "",
      color: "",
      result: "",
    };
  },
  methods: {
    getProfile() {
      Api.noAuth()
        .profile(this.infoToken.document)
        .then((res) => {
          this.info = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        // console.log(this.infoToken);
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getImg() {
      Api.Auth()
        .getImg(this.infoToken.document)
        .then((res) => {
          if (res.data.data == "https://app.brm.co/imgs/imgPerfil.png") {
            this.img =
              "https://duvapi.tars.dev/storage/images/IW5XYkbqmQWGLXE81N4kqjfHnfHoWeKxST5A8tfU.png";
          } else {
            this.img = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getToken();
  },
  mounted() {
    this.result = this.$route.params.result;
    if (this.result == "success") {
      this.color = "#F2F4FF";
    } else {
      this.color = "#324CA1";
    }
    this.getImg();
    this.getProfile();
  },
};
</script>
<style scoped>
@import "./../../assets/css/main.less";
.text-safe {
  font-family: "AsapSemiBold";
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
}
.text-safe-blue {
  color: #141b33;
}
.text-safe-blue-l {
  color: #466be3;
}
.text-safe-while {
  color: #fff;
}
.text-safe-gray {
  color: #626262;
}
.text-safe-name {
  font-family: "AsapSemiBold";
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
  text-transform: capitalize;
}
.text-safe-dependence {
  font-family: "proximaNovaRegular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
}
.text-safe-date {
  font-family: "proximaNovaRegular";
  font-weight: 400;
  font-size: 23px;
  line-height: 30px;
}
.text-safe-date-1 {
  font-family: "proximaNovaRegular";
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
}
.text-safe-title {
  font-family: "AsapRegular";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
}
.text-safe-data {
  font-family: "proximaNovaRegular";
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.img-profile-g {
  border: 3px solid;
  border-radius: 104px;
  border-color: #3b5bc0;
  z-index: 3;
  height: 150px;
  width: 150px;
  position: absolute;
  top: -81px;
  left: 34%;
}

.text-safe-apto {
  font-family: "AsapRegular";
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
     .img-profile-g {
    top: -81px;
    left: 33%;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .img-profile-g {
    top: -81px;
    left: 31%;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .img-profile-g {
    top: -81px;
    left: 31%;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .img-profile-g {
    top: -81px;
    left: 27%;
  }
}
</style>
